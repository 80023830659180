import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth.service';
import { InteractionService } from '../interaction.service';
import { isUserAuthenticated } from '../states/user/user.store';

export function AuthTokenInterceptor(
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
	const authService = inject(AuthService);
	const interactionService = inject(InteractionService);
	if (req.url == environment.authCodeFlowConfig.tokenEndpoint) {
		const headers = req.headers.set('Authorization', 'Bearer AuthorizationTokenRequest');
		const authReq = req.clone({ headers });
		return next(authReq);
	} else if (req.url.startsWith(environment.apiUrl)) {
		if (isUserAuthenticated()) {
			interactionService.reset();
			const token = authService.getAccessToken();
			const headers = req.headers.set('Authorization', `Bearer ${token}`);
			const authReq = req.clone({ headers });
			return next(authReq);
		}
	}

	return next(req);
}
