<router-outlet />

<p-dialog
	header="Falta de actividad"
	[modal]="true"
	[(visible)]="interactionService.showingWarning"
	[style]="{ width: '50rem' }"
	[breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
	<p>Tu sesión expira en 1 minuto por falta de actividad.</p>
	<p>Deseas permanecer en la sesión?</p>

	<div class="flex justify-content-end gap-2">
		<p-button label="Continuar" (onClick)="interactionService.continueSession()" />
	</div>
</p-dialog>
