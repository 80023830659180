import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { InteractionService } from './core/interaction.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	constructor(public authService: AuthService, public interactionService: InteractionService) {}

	ngOnInit(): void {
		const sessionActive = this.authService.checkAndSetSessionActive();
		if (sessionActive) {
			this.interactionService.initializeInteractionTimer();
			this.authService.userInfo$.subscribe();
		}
	}
}
