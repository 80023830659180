import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth.service';

export function UnauthorizedInterceptor(
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
	const authService = inject(AuthService);
	return next(req).pipe(
		catchError(er => {
			let isAuthCall = req.url == environment.authCodeFlowConfig.tokenEndpoint;
			if ((er.status == 403 || er.status == 0) && !authService.hasValidAccessToken()) {
			}

			return throwError(() => er);
		})
	);
}
