<header>
	<div class="logo">Servicios Integrales de Salud</div>

	<a [routerLink]="['app']" class="cta">
		{{ (userAuthenticated$ | async) ? 'Mi Panel' : 'Iniciar Sesión' }}
	</a>
</header>

<section class="hero">
	<div class="hero-content">
		<h1>Bienvenido</h1>
		<p>Plataforma de Servicios Integrales de Salud</p>
	</div>
</section>

<section class="services">
	<h2>Nuestros Servicios</h2>
	<div class="service-cards">
		<div class="service-card">
			<i class="icon-heartbeat"></i>
			<h3>Servicio 1</h3>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, hic, vitae numquam
				officiis beatae in amet doloribus quo temporibus quas, ex autem et praesentium!
				Perferendis doloremque cupiditate consectetur magni sequi.
			</p>
		</div>
		<div class="service-card">
			<i class="icon-dental"></i>
			<h3>Servicio 2</h3>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias rerum, eaque, ex
				voluptatem veniam vel laborum optio eos ad atque eveniet, debitis odio distinctio.
				Quam alias cupiditate expedita nobis dicta.
			</p>
		</div>
		<div class="service-card">
			<i class="icon-eye"></i>
			<h3>Servicio 3</h3>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae, perspiciatis saepe
				maxime sapiente vero quia corporis voluptatum at ea nam ratione tenetur voluptates
				esse quam possimus dolorum perferendis, reiciendis similique!
			</p>
		</div>
	</div>
</section>
<section class="about">
	<div class="about-content">
		<!-- <img src="about-image.jpg" alt="About Us" /> -->
		<div class="about-text">
			<h2>Acerca de nosotros</h2>
			<p>
				Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem
				voluptatibus iure laborum porro quae earum fugit sapiente veniam neque, cumque nam
				optio commodi obcaecati molestiae quisquam et inventore error ipsa.
			</p>
		</div>
	</div>
</section>
