import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { CallbackComponent } from './core/components/callback/callback.component';
import { ErrorComponent } from './core/components/error/error.component';
import { LoginComponent } from './core/components/login/login.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { initApp, LocalStorageService } from './core/init';
import { AuthTokenInterceptor } from './core/interceptors/auth.interceptor';
import { UnauthorizedInterceptor } from './core/interceptors/unauthorized.interceptor';
import { LandingComponent } from './landing/landing.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		CallbackComponent,
		ErrorComponent,
		NotFoundComponent,
		LandingComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		OAuthModule.forRoot(),
		DialogModule,
		ButtonModule,
		ProgressSpinnerModule,
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: initApp, deps: [AuthService], multi: true },
		provideHttpClient(withInterceptors([UnauthorizedInterceptor, AuthTokenInterceptor])),
		{ provide: OAuthStorage, useClass: LocalStorageService },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private primengConfig: PrimeNGConfig) {
		this.primengConfig.setTranslation({
			dateFormat: 'dd/mm/yy',
			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
			dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
			monthNames: [
				'Enero',
				'Febrero',
				'Marzo',
				'Abril',
				'Mayo',
				'Junio',
				'Julio',
				'Agosto',
				'Septiembre',
				'Octubre',
				'Noviembre',
				'Diciembre',
			],
			monthNamesShort: [
				'Ene',
				'Feb',
				'Mar',
				'Abr',
				'May',
				'Jun',
				'Jul',
				'Ago',
				'Sep',
				'Oct',
				'Nov',
				'Dic',
			],
			today: 'Hoy',
			weekHeader: 'Semana',
		});
	}
}
