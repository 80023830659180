import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './core/components/callback/callback.component';
import { ErrorComponent } from './core/components/error/error.component';
import { LoginComponent } from './core/components/login/login.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
	{
		path: '',
		component: LandingComponent,
		pathMatch: 'full',
	},
	{
		path: 'app',
		canActivate: [AuthGuard],
		loadChildren: () => import('./main/main.module').then(m => m.MainModule),
		title: 'Inicio',
	},
	{
		path: 'login',
		component: LoginComponent,
		pathMatch: 'full',
	},
	{
		path: 'login/callback',
		component: CallbackComponent,
		pathMatch: 'full',
	},
	{
		path: 'error',
		component: ErrorComponent,
		pathMatch: 'full',
		title: 'Error',
	},
	{
		path: '**',
		component: NotFoundComponent,
		canActivate: [AuthGuard],
		title: 'No encontrado',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
