import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { userAuthenticated$ } from '../core/states/user/user.store';

@Component({
	selector: 'app-landing',
	templateUrl: 'landing.component.html',
	styleUrls: ['landing.component.scss'],
})
export class LandingComponent implements OnInit {
	public userAuthenticated$: Observable<boolean> = userAuthenticated$;
	constructor() {}

	ngOnInit() {}
}
