import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-error',
	templateUrl: 'error.component.html',
})
export class ErrorComponent implements OnInit {
	public errorMessage: string | null = '';
	constructor(private router: ActivatedRoute) {}

	ngOnInit() {
		this.errorMessage = this.router.snapshot.paramMap.get('err');
	}
}
