import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { userAuthenticated$ } from '../states/user/user.store';

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const router = inject(Router);
	return userAuthenticated$.pipe(
		map((isAuthenticated: boolean) => {
			if (isAuthenticated) {
				return true;
			} else {
				return router.createUrlTree(['login'], {
					queryParams: { urlState: state.url },
				});
			}
		})
	);
};
