import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth.service';

@Component({
	selector: 'app-login',
	templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
	constructor(private authService: AuthService, private route: ActivatedRoute) {}

	ngOnInit() {
		let urlState = this.route.snapshot.queryParams['urlState'] ?? '';
		this.authService.login(urlState);
	}
}
