import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from '../auth.service';

export function initApp(authService: AuthService) {
	return (): Promise<any> => authService.init();
}

@Injectable()
export class LocalStorageService implements OAuthStorage {
	getItem(key: string): string | null {
		return localStorage.getItem(key);
	}

	removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	setItem(key: string, data: string): void {
		localStorage.setItem(key, data);
	}
}
